import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Button } from "src/components/ui/button";
import SlotSelectDialog from "src/components/ui/slot-select-dialog";
import { ScheduledExam } from "src/lib/entities";
import { cn, convertDateToIST, convertTimeToIST } from "src/lib/utils";
import { createAdmitCard, getAdmitCard } from "src/redux/api/admit-card";
import { useFetchAppliedJobs } from "src/redux/api/hooks/useFetchAppliedJobs";
import { useFetchExams } from "src/redux/api/hooks/useFetchExams";
import { useFetchFilteredDateSlots } from "src/redux/api/hooks/useFetchFilteredDateSlots";

interface SelectSlotsProps {
  selectedDay: Date;
  handleBack: () => void;
}

export const SelectSlots = ({ selectedDay, handleBack }: SelectSlotsProps) => {
  const { appliedJobs } = useFetchAppliedJobs();
  const [selectedSlotId, setSelectedSlotId] = useState<number | null>(null);
  const [submissionId, setSubmissionId] = useState<number>();
  const [appliedPost, setAppliedPost] = useState<string>("");
  const [appliedState, setAppliedState] = useState<string>("");
  const { scheduledExams } = useFetchExams();

  const scheduledExamsArray: ScheduledExam[] = [];
  scheduledExams.forEach((exam) => {
    scheduledExamsArray.push(exam.exam);
  });

  const scheduledStatesData = (array: ScheduledExam[]) => {
    const result: { [key: string]: string } = {};
    array.forEach((exam) => {
      result[exam.postName] = exam.slot.state;
    });
    return result;
  };

  const scheduledExamStates = scheduledStatesData(scheduledExamsArray);

  function getScheduledState(postName: string): string | undefined {
    return scheduledExamStates[postName];
  }

  const examState = getScheduledState(appliedPost);

  const navigate = useNavigate();

  const { filteredSlots, isPending } = useFetchFilteredDateSlots({
    selectedDate: selectedDay,
  });

  const handlePost = (subId: number, jpost: string, jstate: string) => {
    setSubmissionId(subId);
    setAppliedPost(jpost);
    setAppliedState(jstate);
  };

  const [loading, setLoading] = useState(false);
  const [slotloading, setSlotLoading] = useState(false);
  const [isBooked, setIsBooked] = useState(false);

  const slotAlreadyBooked = async (submissionId: number) => {
    setSlotLoading(true);
    if (submissionId) {
      const scheduled = await getAdmitCard(submissionId);
      if (scheduled && !!scheduled.status) {
        setIsBooked(true);
      } else {
        setIsBooked(false);
      }
    }
    setSlotLoading(false);
  };
  useEffect(() => {
    slotAlreadyBooked(submissionId!);
  }, [submissionId]);

  const handleSchedule = async () => {
    setLoading(true);
    try {
      if (!selectedSlotId && !submissionId) {
        toast.error("Please Select the slot.");
      } else {
        const res = await createAdmitCard(selectedSlotId!, submissionId!);
        if (res.message.toLowerCase() === "success") {
          toast.success("Admit card created.");
          navigate(`/admit-card/${submissionId}`);
        }
      }
    } catch (error) {
      toast.error("Something went wrong try again after some time!");
    } finally {
      setLoading(false);
    }
  };

  const scheduledExamsPostArray: string[] = [];
  scheduledExams.forEach((exam) => {
    scheduledExamsPostArray.push(exam.exam.postName);
  });

  const availableSlotsArray: string[] = [];
  filteredSlots.forEach((slot) => {
    availableSlotsArray.push(slot.catagory);
  });

  const availableStateArray: string[] = [];
  filteredSlots.forEach((slot) => {
    availableStateArray.push(slot.state);
  });

  const slotState = availableStateArray.find((state) => state === appliedState);
  const slotPost = availableSlotsArray.find((post) => post === appliedPost);
  const scheduledPost = scheduledExamsPostArray.find(
    (post) => post === appliedPost,
  );

  return (
    <div className="flex h-full flex-col items-center justify-center gap-8 p-3">
      <>
        <div className="flex flex-col">
          <span className="mb-6 text-center text-xl font-medium">
            Select the Applied Post:
          </span>
          <div className="flex flex-wrap justify-evenly gap-3">
            {Array.isArray(appliedJobs) &&
              appliedJobs.map((job) => {
                return (
                  <button
                    key={job.submissionId}
                    className={`flex w-32 flex-col items-center rounded-md border-2 border-gray-500 p-2  hover:bg-primary hover:text-white ${submissionId === job.submissionId ? "bg-primary font-semibold text-white" : ""}`}
                    onClick={() =>
                      handlePost(
                        job.submissionId,
                        job.postApplied,
                        job.baseDistrictOrState,
                      )
                    }
                  >
                    <span className="text-lg font-medium">
                      {job.postApplied}
                    </span>
                    <span className="text-md">{job.baseDistrictOrState}</span>
                  </button>
                );
              })}
          </div>
        </div>

        <span className="text-xl font-medium">Select Exam Slot for</span>
        {selectedDay ? (
          <span className="text-2xl font-bold">
            {convertDateToIST(new Date(selectedDay).toUTCString())}
          </span>
        ) : (
          ""
        )}
        <div>
          {slotloading ? (
            <div>Loading...</div>
          ) : (
            <div>
              {!!slotPost && !!slotState ? (
                <div>
                  {isBooked || !!scheduledPost ? (
                    <div className="flex flex-col items-center justify-center">
                      <p className="text-2xl font-medium text-primary">
                        You've already scheduled your exam for{" "}
                        {appliedPost.toUpperCase()} in {examState}.
                      </p>
                      <p className="my-2 w-[60%] text-wrap text-center text-lg text-stone-900">
                        You can only take the exam once per post. However, we
                        will consider your result for selection in other states
                        based on your preferences.
                      </p>
                      <p className="my-2 w-[60%] text-wrap text-center text-stone-700">
                        View your Admit Card by clicking the green colored post
                        card in the Applied post section for more details about
                        your Exam.
                      </p>
                    </div>
                  ) : (
                    <div className="mx-auto grid max-w-screen-sm grid-cols-1 gap-2 md:grid-cols-2">
                      {Array.isArray(filteredSlots) &&
                        filteredSlots.map(
                          (slot) =>
                            slot.state === appliedState &&
                            slot.catagory === appliedPost && (
                              <Button
                                key={slot.id}
                                className={cn(
                                  "mx-auto flex w-56 flex-col items-center justify-center px-10 py-14",
                                  selectedSlotId === slot.id
                                    ? "border border-green-500 bg-[#ace1af] text-lg text-black shadow-md hover:border-green-500 hover:bg-[#b5e4b7]"
                                    : "",
                                )}
                                onClick={() =>
                                  setSelectedSlotId(
                                    selectedSlotId === slot.id ? null : slot.id,
                                  )
                                }
                                disabled={
                                  slot.maxCapacity === 0 ||
                                  isBooked ||
                                  slot.state !== appliedState
                                }
                              >
                                <span
                                  className={`${selectedSlotId === slot.id ? "text-black" : "text-white"} font-medium`}
                                >
                                  {slot.slotName}
                                </span>
                                {`${convertTimeToIST(slot.startTime)} - ${convertTimeToIST(
                                  slot.endtime,
                                )}`}
                                {slot.maxCapacity === 0 && (
                                  <span className="text-md text-black">
                                    Slot is full, try a different Slot.
                                  </span>
                                )}
                              </Button>
                            ),
                        )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {appliedPost && (
                    <span className="text-lg font-medium text-primary">{`No Slots Available for ${appliedPost}( ${appliedState} ) Post.`}</span>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <SlotSelectDialog onClick={handleSchedule}>
          <Button disabled={!selectedSlotId || isPending || isBooked}>
            {isPending || loading ? "Scheduling..." : "Schedule Confirm"}
          </Button>
        </SlotSelectDialog>
        <button
          onClick={handleBack}
          className="w-26 h-10 cursor-pointer rounded-md border-2 border-red-600 bg-red-400 p-2 uppercase text-white"
        >
          Go back?
        </button>
      </>
    </div>
  );
};
