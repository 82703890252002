import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface AnswerStore {
  mcqanswers: Array<{
    optionalAnswer: string | "";
  }>;
  saveMcqAnswer: (
    index: number,
    answer: {
      optionalAnswer: string | "";
    },
  ) => void;
  uuid: string;
  setUuid: (uuid: string) => void;
  clearMcqStorage: () => void;
}

export const useMcqAnswerStore = create(
  persist<AnswerStore>(
    (set, get, api) => ({
      mcqanswers: [],

      saveMcqAnswer: (index, answer) =>
        set((state) => {
          const newAnswers = [...state.mcqanswers];
          newAnswers[index] = answer;
          return { mcqanswers: newAnswers };
        }),
      uuid: "",
      setUuid: (uuid) => set({ uuid }),

      clearMcqStorage: () => {
        api.persist.clearStorage();
        set({ mcqanswers: [] });
      },
    }),
    {
      name: "mcqanswer-store",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

interface LongAnswerStore {
  longanswers: Array<{
    descriptiveAnswer: string | "";
    answerUrl: string | "";
  }>;
  savelongAnswer: (
    index: number,
    answer: {
      descriptiveAnswer: string | "";
      answerUrl: string | "";
    },
  ) => void;
  uuid2: string;
  setUuid2: (uuid2: string) => void;
  clearDescStorage: () => void;
}

export const useLongAnswerStore = create(
  persist<LongAnswerStore>(
    (set, get, api) => ({
      longanswers: [],

      savelongAnswer: (index, answer) =>
        set((state) => {
          const newAnswers = [...state.longanswers];
          newAnswers[index] = answer;
          return { longanswers: newAnswers };
        }),
      uuid2: "",
      setUuid2: (uuid2) => set({ uuid2 }),

      clearDescStorage: () => {
        api.persist.clearStorage();
        set({ longanswers: [] });
      },
    }),
    {
      name: "longanswer-store",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
