import {
  AppliedPost,
  AppliedPostSkeleton,
} from "src/components/ui/applied-post";
import { useFetchAppliedJobs } from "src/redux/api/hooks/useFetchAppliedJobs";

interface AppliedPostsProps {
  viewAdmitCard: (id: number) => void;
  isScheduled: number[];
}

export const AppliedPosts = ({viewAdmitCard, isScheduled}: AppliedPostsProps) => {
  const { appliedJobs, isPending } = useFetchAppliedJobs();

  const getImageSrc = (jobRole: string) => {
    return `${jobRole}.jpg`;
  };

  if ((appliedJobs && isPending) || appliedJobs.length === 0) {
    return <AppliedPostSkeleton />;
  }

  return (
    <div className="flex w-full flex-wrap whitespace-nowrap rounded-md">
      <div className="flex flex-wrap justify-evenly gap-3">
        {appliedJobs.map((job) => (
          <AppliedPost
            key={job.submissionId}
            submissionId={job.submissionId}
            imageSrc={getImageSrc(job.postApplied)}
            postApplied={job.postApplied}
            speciality={job.speciality}
            baseDistrictOrState={job.baseDistrictOrState}
            viewAdmitCard={viewAdmitCard}
            isScheduled={isScheduled}
          />
        ))}
      </div>
    </div>
  );
};
