"use client";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";
import { useMcqAnswerStore } from "src/components/zustand/AnswerStore";
import { submitExam } from "src/redux/api/exam";
import useQueryParams from "src/redux/api/hooks/useQueryParams";


export default function SubmittedPage() {
  const queryParams = useQueryParams(["admit", "exam"]);
  useEffect(() => {
    submitExam(queryParams.admit!, queryParams.exam!);
  });

  const navigate = useNavigate();

  const { clearMcqStorage } = useMcqAnswerStore();

  return (
    <div className="flex flex-col h-screen justify-center items-center max-w-screen-lg mx-auto space-y-10 p-10">
      <span className="text-xl text-center md:text-3xl">
        Thank you for submitting exam online.
        <br />
        We anticipate releasing the exam results soon.
        <br />
        Please stay tuned for further updates
      </span>
      <Button variant="default"
        onClick={() => {
          navigate("/");
          clearMcqStorage();
        }}
      >
        Home
      </Button>
    </div>
  );
}
