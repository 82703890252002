import { useEffect, useState } from "react";
import { ScheduledExam } from "src/lib/entities";
import { getScheduledExams } from "../exam";

export const useFetchExams = () => {
  const [scheduledExams, setScheduledExams] = useState<
    { exam: ScheduledExam; isExamCloseForPost: boolean; isMissed: boolean }[]
  >([]);
  const [isPending, setIsPending] = useState(true);

  const isExamClose = (startTime: string): boolean => {
  // Convert the exam start time string to a Date object
  const examStartTime = new Date(startTime);
  const currentTime = new Date();

  // Calculate the allowed entry time range
  const earliestEntryTime = new Date(examStartTime);
  earliestEntryTime.setMinutes(examStartTime.getMinutes() - 45); // 45 minutes before start

  const latestEntryTime = new Date(examStartTime);
  latestEntryTime.setMinutes(examStartTime.getMinutes() - 10); // 10 minutes before start

  // Check if the current time is within the allowed entry window
  return currentTime >= earliestEntryTime && currentTime <= latestEntryTime;
};

  const isExamMissed = (startTime: string): boolean => {
  // Convert the exam start time string to a Date object
  const examTime = new Date(startTime);
  const now = new Date();

  // Calculate the time 10 minutes before the exam start time
  const latestEntryTime = new Date(examTime);
  latestEntryTime.setMinutes(examTime.getMinutes() - 10);

  // Return true if the current time is after 10 minutes before the exam start time
  return now > latestEntryTime;
};

  useEffect(() => {
    const fetchScheduledExams = async () => {
      try {
        const { data } = await getScheduledExams();
        const promises = data.scheduledExam.map(async (exam: any) => {
          const isExamCloseForPost = isExamClose(exam.slot.startTime);
          const isMissed = isExamMissed(exam.slot.startTime);
          return { exam, isExamCloseForPost, isMissed };
        });
        const result = await Promise.all(promises);
        setScheduledExams(result);
      } catch (error) {
        console.error("Error fetching scheduled exams:", error);
      } finally {
        setIsPending(false);
      }
    };

    fetchScheduledExams();
  }, []);
  return { scheduledExams, isPending };
};
