"use client";

import { AlertTriangle } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { toast, Toaster } from "sonner";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Label } from "src/components/ui/label";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { ScrollArea } from "src/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/ui/table";
import {
  useMcqAnswerStore,
} from "src/components/zustand/AnswerStore";

import { Modal } from "react-overlays";
import Backdrop from "src/components/ui/Backdrop";
import SubmitExamDialog from "src/components/ui/submit-exam-dialog";

import { useNavigate } from "react-router-dom";
import { optionalQuestions } from "./questions";

export default function MockMcqExamPage() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(true);
  const [showOptionalAnswerReviewModal, setShowOptionalAnswerReviewModal] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const handle = useFullScreenHandle();

  const [optionalAnswer, setOptionalAnswer] = useState<string | "">("");

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const { mcqanswers, saveMcqAnswer, clearMcqStorage } = useMcqAnswerStore();

  const [isActive, setIsActive] = useState(true);
  const [warningCount, setWarningCount] = useState(0);
  const maxWarnings = 3;
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (isFirstVisit) {
      clearMcqStorage();
    }
  }, [isFirstVisit]);


  // handle refresh of the page.
  const handleRefresh = useRef((event: { returnValue: string }) => {
    const confirmationMessage = "Are you sure you want to leave?";
    const userConfirmed = window.confirm(confirmationMessage);

    if (userConfirmed) {
      handleExamSubmit();
    }

    event.returnValue = confirmationMessage; // Standard for most browsers
    return confirmationMessage; // For some older browsers
  }).current;

  const handleTabChangeWarning = () => {
    const confirmationMessage = `Warning: Please do not switch tabs or leave this page. You have ${maxWarnings - warningCount} warnings remaining. Exceeding the limit will result in automatic submission of your Exam.`;

    window.alert(confirmationMessage);
    setWarningCount((prevCount) => prevCount + 1);   
  }

  useEffect(() => {
    const handlePageChange = async () => {
      if (document.hidden) {
        if (warningCount < maxWarnings) {
          handleTabChangeWarning();
        } else {
          handleExamSubmit();
          setIsActive(false);
        }
      }
    };

    document.addEventListener("visibilitychange", handlePageChange);
    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      document.removeEventListener("visibilitychange", handlePageChange);
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, [isActive, warningCount]);


  const clearLocalStateAnswers = () => {
    setOptionalAnswer("");
  };

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  const handleSaveAndNextMcq = async () => {
    if (currentQuestionIndex > optionalQuestions.length - 1) {
      return;
    }

    if (!!optionalAnswer) {

        saveMcqAnswer(currentQuestionIndex, {
          optionalAnswer:
            optionalAnswer || mcqanswers[currentQuestionIndex]?.optionalAnswer,
        });
        toast.success("Answer saved successfully");

        if (currentQuestionIndex !== optionalQuestions.length - 1) {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
    } else {
      if (currentQuestionIndex !== optionalQuestions.length - 1) {
        toast.warning("Select an Answer to 'Save and Next' or 'Skip'");
      }
    }

    clearLocalStateAnswers();
  };

  const handleSkip = () => {
    if (currentQuestionIndex >= optionalQuestions.length - 1) {
      return;
    }
    clearLocalStateAnswers();

    toast.success("Skipped");

    if (currentQuestionIndex !== optionalQuestions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex === 0) {
      return;
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleExamSubmit = () => {
    handleSaveAndNextMcq();

    window.removeEventListener("beforeunload", handleRefresh);

    clearMcqStorage();

    navigate(
      `/mock/exam/finish`,
    );
  };

  const handleAutoExamSubmit = () => {

    window.removeEventListener("beforeunload", handleRefresh);

    clearMcqStorage();

    navigate(
      `/mock/exam/finish`,
    );
  };

  const enterFullScreen = () => {
    handle.enter();
    setShowWarningModal(false);
  };

  useEffect(() => {
    setIsFullScreen(handle.active);
    openWarningModal();
  }, [handle.active]);

  const openWarningModal = () => {
    if (!isFullScreen && !isFirstVisit) {
      setShowWarningModal(true);
    }
  };

  // handles first section timer end.
  const handleTimerEnd = () => {
    handleAutoExamSubmit();
  };

  async function handleNavigation(index: number): Promise<void> {
    clearLocalStateAnswers();
    setCurrentQuestionIndex(index);
  }

  return (
    <div>
      <FullScreen
        className="container mx-auto h-screen bg-white px-4 py-10"
        handle={handle}
      >
        {/* <ShadCNToaster /> */}
        <Toaster position="top-center" richColors className="z-[9999999]" />
              <div className="grid grid-cols-4 gap-10">
                <div className="col-span-3">
                  <div className="mb-6">
                    <div className="flex justify-start">
                      <div className="flex rounded-md bg-gray-200 px-4 py-1">
                        <McqCountdownTimer
                          onTimerEnd={handleTimerEnd}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-10">
                    <h1 className="text-2xl font-semibold">
                      {loading
                        ? "Loading..."
                        : `${currentQuestionIndex + 1}. ${
                            optionalQuestions[currentQuestionIndex]?.question
                          }`}
                    </h1>
                  </div>
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    optionalQuestions[currentQuestionIndex]?.type ===
                      "optional" && (
                      <RadioGroup className="grid h-4/5 grid-cols-1 grid-rows-4 gap-4">
                        {Array.from({ length: 4 }, (_, index) => index + 1).map(
                          (optionNumber) => {
                            const optionKey = `option${optionNumber}`;
                            const optionId = `option${optionNumber}`;
                            const optionValue = (
                              optionalQuestions[currentQuestionIndex] as any
                            )[optionKey];

                            return (
                              <div
                                key={optionKey}
                                className={`flex items-center justify-start rounded-md bg-gray-200 hover:cursor-pointer hover:bg-gray-300 ${
                                  optionalAnswer === optionValue
                                    ? "bg-gray-400"
                                    : ""
                                }`}
                                onClick={() => setOptionalAnswer(optionValue)}
                              >
                                {/* if answer is available in the question object then check it.*/}
                                <RadioGroupItem
                                  value={optionValue}
                                  id={optionId}
                                  checked={
                                    optionalAnswer === optionValue ||
                                    mcqanswers[currentQuestionIndex]
                                      ?.optionalAnswer === optionValue
                                  }
                                  className="ml-5"
                                  onChange={() =>
                                    setOptionalAnswer(optionValue)
                                  }
                                />
                                <Label className="ml-2" htmlFor={optionId}>
                                  {optionValue}
                                </Label>
                              </div>
                            );
                          },
                        )}
                      </RadioGroup>
                    )
                  )}
                  {currentQuestionIndex !== 0 && (
                    <Button
                      className="fixed bottom-5 left-5 w-64 p-5"
                      onClick={handlePrevious}
                    >
                      Previous
                    </Button>
                  )}
                </div>
                <div className="col-span-1">
                  <ScrollArea className="mt-16 h-[60vh] grow">
                    <ul className="space-y-2 overflow-y-auto">
                      {optionalQuestions.map((_, index) => (
                        <li
                          key={index}
                          className={`${currentQuestionIndex === index ? "font-semibold text-primary" : ""} flex flex-row items-center rounded-md px-1 hover:bg-secondary`}
                        >
                          <Checkbox
                            id={`Question ${index + 1}`}
                            checked={!!mcqanswers[index]?.optionalAnswer}
                          />
                          <span className="mx-1">{`Q.${index + 1}`}</span>
                          <div
                            className="ml-1 flex min-h-10 cursor-pointer items-center justify-start text-nowrap p-1 pr-2"
                            onClick={() => handleNavigation(index)}
                          >
                            {optionalQuestions[index].question.length >
                            35
                              ? `${optionalQuestions[index].question.slice(0, 35)}...`
                              : `${optionalQuestions[index].question}`}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </ScrollArea>

                  <div className="fixed bottom-5 right-5 space-x-3 space-y-3">
                    {currentQuestionIndex !== optionalQuestions.length - 1 && (
                      <Button
                        className={`w-32 p-5 ${
                          (
                            !!optionalAnswer) &&
                          "hidden"
                        }`}
                        onClick={handleSkip}
                      >
                        Skip
                      </Button>
                    )}
                    {currentQuestionIndex !== optionalQuestions.length - 1 ? (
                      <Button
                        className="w-56 p-5"
                        onClick={handleSaveAndNextMcq}
                      >
                        Save and Next
                      </Button>
                    ) : (
                      <Button
                        className="w-64 p-5"
                        onClick={() => {
                          handle.exit();
                          setShowWarningModal(false);
                          handleSaveAndNextMcq();
                          setShowOptionalAnswerReviewModal(true);
                        }}
                      >
                        Save and Review
                      </Button>
                    )}
                  </div>
                </div>
              </div>
      </FullScreen>
      <Modal
        show={showOptionalAnswerReviewModal}
        onHide={() => setShowOptionalAnswerReviewModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          borderRadius: "8px",
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div>
          <ScrollArea className="h-[75vh] ">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-48">Questions</TableHead>
                  <TableHead className="pr-4 text-right">Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {optionalQuestions.map((question, index) => (
                  <TableRow key={index}>
                    <TableCell className="w-[80%] truncate text-wrap font-medium">
                      {`${index + 1}.${question.question}`}
                    </TableCell>
                    <TableCell className="pr-4 text-right">
                      {!!mcqanswers[index]?.optionalAnswer
                        ? "Attempted"
                        : "Not Attempted"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
          <div className="flex w-full justify-end gap-2">
            <Button
              onClick={() => {
                handle.enter();
                setShowOptionalAnswerReviewModal(false);
              }}
            >
              Go back
            </Button>
            <SubmitExamDialog onClick={handleExamSubmit}>
              <Button className="font-medium text-white">Submit</Button>
            </SubmitExamDialog>
          </div>
        </div>
      </Modal>
      <Modal
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        aria-labelledby="start exam warning!"
        style={{
          border: "none",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#e0daed",
          width: "60%",
          padding: "20px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1040,
        }}
      >
        <div className="flex flex-col gap-5">
          <div className="mb-4 text-center text-2xl font-bold text-red-600">
            {isFirstVisit ? "Start the exam" : "Warning"}
          </div>
          {isFirstVisit ? (
            <>
              <div className="mb-4 flex flex-col items-center justify-center text-center">
                <p className="text-xl font-semibold text-red-600">
                  Please don't refresh or change internet browser tabs during
                  the Exam.
                </p>
                <p>
                  Any attempt to leave the screen will result in a submission of
                  the exam.
                </p>
              </div>
              <Button
                onClick={() => {
                  setShowWarningModal(false);
                  setIsFirstVisit(false);
                  enterFullScreen();
                  setLoading(false);
                }}
                disabled= {optionalQuestions.length === 0 }
              >
                Okay
              </Button>
            </>
          ) : (
            <div className="flex flex-col items-center gap-8">
              <div className="flex flex-col items-center justify-center text-red-600">
                <AlertTriangle size="32" />
                <p>You are not in fullscreen mode.</p>
                <p>
                  Any attempt to leave the screen will result in a submission of
                  the exam.
                </p>
              </div>
              <Button onClick={enterFullScreen}>Go Fullscreen</Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

interface CountdownTimerProps {
  onTimerEnd: () => void;
}

const McqCountdownTimer: React.FC<CountdownTimerProps> = ({
  onTimerEnd,
}) => {
  const [remainingTime, setRemainingTime] = useState<string>("00:00");

  const serverTimeString = new Date().toUTCString();
  const serverTime = new Date(serverTimeString).getTime();
  const localTime = Date.now();
  const offset = serverTime - localTime;
  const targetTimemili = new Date().getTime() + 5 * 60 * 1000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const adjustedTime = now + offset;

      const timeRemaining = Math.max(0, targetTimemili - adjustedTime);

      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        onTimerEnd();
      } else {
        const minutes = Math.floor(timeRemaining / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        setRemainingTime(
          `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
        );
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  if(remainingTime === "02:00") {
    toast.warning("2 Minutes Remaining");
  }

  return (
    <>
      <span className="font-mono text-xl">{remainingTime}</span>
    </>
  );
};
