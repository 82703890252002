const CandidatesInstruction = () => {
  return (
    <>
      <div className="mb-4">
        <h3 className="mb-2 text-center text-lg font-semibold text-red-500">
          IMPORTANT INSTRUCTIONS FOR CANDIDATES
        </h3>
        <h2 className="my-2">DOS</h2>
        <ol className="list-decimal pl-4 text-sm">
          <li>A fast, stable internet connection is required.</li>
          <li>
            A Few blank white papers and a Blue Ball-pen (other colored pens are
            not allowed) are required to complete the descriptive answers.
          </li>
          <li>A pencil and eraser are needed.</li>
          <li>
            You must verify your identity using a photo ID that has your Name,
            Photo, and Signature on the same side.
          </li>
          <li>
            The candidate’s attention must remain focused on the screen
            throughout the exam.
          </li>
          <li>
            You have to show your room if asked by the invigilator during the
            Exam.
          </li>
          <li>
            A good device for appearing in the Exam (Laptop or Desktop with
            Webcam) is required.
          </li>
          <li>Exam will not be mobile compatible.</li>
          <li>
            The camera quality of the Smart Phone should be good so that the
            scan and upload quality of the long answers are well-visible (A
            Smart Phone will be allowed for uploading long answer at the
            appropriate time only)
          </li>
          <li>A live photo must be uploaded before the Exam starts.</li>
          <li>
            No candidates should adopt any unfair means, or indulge in any
            unfair examination practice.
          </li>
        </ol>
        <h2 className="my-2 text-red-500">DON'TS</h2>
        <ol className="list-decimal pl-4 text-sm">
          <li>Wearing of headphones or headsets is not permitted.</li>
          <li className="text-red-500">
            No cell phones or other secondary devices are allowed in the room or
            test area, apart from your Primary device through which you are
            appearing the Exam (A Smart Phone will be allowed for uploading long
            answer at the appropriate time only)
          </li>
          <li>No one else can be in the room with you.</li>
          <li>
            Talking or gossiping during the Examination Breaks are not permitted
            during the exam.
          </li>
          <li>Attending phone calls during the Examination is not allowed.</li>
          <li className="text-red-500">
            Candidates are not allowed to stop their Camera or Video during the
            whole examination duration.
          </li>
          <li className="text-red-500">
            Blurred background in Video Streaming is not allowed.
          </li>
          <li className="text-red-500">
            Candidates must be in Decent Dress-code in the exam, no Homely Dress
            allowed
          </li>
          <li className="text-red-500 font-bold">
            Your video will be recorded. If any suspicious activities are observed during the exam, it may result in disqualification from the final interview round due to a lack of honesty.
          </li>
          <li className="text-red-500">
            Violation of any of the above and below rules and terms & conditions
            of the Examination or the Application or the company as well as
            NHWM, the candidates' candidature will be cancelled automatically
            along with disciplinary action without prior notice.
          </li>
        </ol>
      </div>
      <div className="border-t pt-4 text-justify text-sm">
        <p className="mb-1">
          NB: NHWM and its supportive organization are not responsible if you
          are unable to attend the exam due to network issues or technical
          glitches in your device.
        </p>
        <p className="mb-1">
          If appearing from an Internet Cafe, be sure that no disturbance at all
          should be in the cafe. If any candidate is found guilty of the rules
          of the Examination or found violating the Terms and Conditions of the
          organization, they will be terminated from the Exam, and no further
          attempt will be allowed.
        </p>
        <p className="mb-1">
          This rule is applicable even after completion or successful submission
          of the answer script of the Examination. If a candidate is found
          guilty in any one exam, he/she will be terminated for all exams he/she
          has already appeared in or is yet to appear in.
        </p>
        <p>
          NHWM decision will be the final decision. Candidates are not permitted
          to retake or request a re-evaluation of the exam. Selection will be
          based solely on merit. Bench-Mark will be decided by NHWM according to
          the post to be appointed by NHWM.
        </p>
      </div>
    </>
  );
};

export default CandidatesInstruction;
