"use client";

import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import { ChangeEvent, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "src/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "src/components/ui/form";
import { Input } from "src/components/ui/input";

const MockUploadAnswer = () => {

  interface FormProps {
    answerImage: File | undefined;
  }

  const form = useForm<FormProps>();

  const [answerImagePrev, setAnswerImagePrev] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [success1, setSuccess1] = useState("");

  const fileCaptureRef = useRef<HTMLInputElement>(null);

  const handleCapturedFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const capturedFile = event.target.files?.[0];
    if (capturedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAnswerImagePrev(e.target?.result as string);
      };
      reader.readAsDataURL(capturedFile);
    }

    setError("");

      if (!capturedFile) {
        setError("Please select an image to upload.");
        return;
      }

      // Step 1: Upload answer image


      // Step 3: Confirm answer image upload


        setSuccess("Great, Answer uploaded successfully!");
        setSuccess1("Click on Save and Next in the Exam portal to continue!");
      
    
  };

  const openCamera = () => {
    if (fileCaptureRef.current) {
      fileCaptureRef.current.click();
    }
  };

  const onSubmit = async (values: FormProps) => {
    setError("");

      if (!values.answerImage) {
        setError("Please select an image to upload.");
        return;
      }

      // Step 1: Upload answer image

      // Step 2: Save answer image metadata


      // Step 3: Confirm answer image upload


        setSuccess("Great, Answer uploaded successfully!");
        setSuccess1("Click on Save and Next in the Exam portal to continue!");


      // Step 2: Save answer image metadata

  };

  const FormError = ({ message }: { message?: string }) => {
    if (!message) return null;

    return (
      <div className="flex items-center gap-x-2 rounded-md bg-destructive/15 p-3 text-sm text-destructive">
        <ExclamationTriangleIcon className="h-4 w-4" />
        <p>{message}</p>
      </div>
    );
  };

  const FormSuccess = ({
    message1,
    message2,
  }: {
    message1: string;
    message2: string;
  }) => {
    if (!message1) return null;

    return (
      <div className="flex flex-col items-center justify-center gap-x-2 rounded-md bg-emerald-500/15 p-3 text-center text-sm text-emerald-500">
        <CheckCircledIcon className="size-8" />
        <p className="text-md font-semibold">{message1}</p>
        <p>{message2}</p>
      </div>
    );
  };

  return (
    <div className="m-auto flex min-h-screen flex-col items-center justify-center gap-3 overflow-y-auto">
      <div className="flex flex-col items-center justify-center gap-3 rounded-md px-2 py-4 outline outline-1 outline-primary">
        <div className="rounded-md border border-violet-300 p-1">
          {!!answerImagePrev && (
            <img
              src={answerImagePrev}
              alt=""
              width={300}
              className="aspect-[3/4]"
            />
          )}
        </div>

        <div className="my-3 flex w-full flex-col items-center justify-center rounded-md border border-violet-300 py-3">
          <span>Capture your Answer</span>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            ref={fileCaptureRef}
            style={{ display: "none" }} // Hide the input visually
            onChange={handleCapturedFile}
          />
          <p>Click below to Open Camera</p>
          <div className="size-fit rounded-full border border-black p-1">
            <button
              onClick={openCamera}
              className="size-14 rounded-full border border-purple-700 bg-purple-300"
            >
              Click
            </button>
          </div>
        </div>
        {error && <FormError message={error} />}
        {success && <FormSuccess message1={success} message2={success1} />}
        <div className="flex flex-col items-center justify-center rounded-md border border-violet-300">
          <span>Upload Your Answer</span>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="answerImage"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...fieldProps}
                        type="file"
                        className="block h-[3rem] w-full text-sm text-gray-500
                       file:mr-4 file:rounded-full file:border-0
                       file:bg-blue-50 file:px-3
                       file:py-2 file:text-sm
                       file:font-semibold file:text-[#1b271c]
                       hover:file:bg-[#9f88dd]"
                        accept="image/*"
                        onChange={(event) => {
                          const file =
                            event.target.files && event.target.files[0];
                          onChange(file);
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              setAnswerImagePrev(e.target?.result as string);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <div className="my-2 flex items-center justify-center py-1">
                <Button disabled={form.formState.isSubmitting} type="submit">
                  Upload Answer
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default MockUploadAnswer;
