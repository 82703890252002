import { Route, RouteProps, Routes } from "react-router-dom";
import { DashboardView } from "./screens/Dashboard";
import { NotFound } from "./screens/NotFound";
import { SignInView } from "./screens/Auth/sign-in";
import { ForgotPasswordView } from "./screens/Auth/forgot-password";
import { ResetPasswordView } from "./screens/Auth/reset-password";
import { useAuth } from "./redux/api/hooks/useAuth";
import { AppliedPostsView } from "./screens/AppliedPosts";
import { BooksView } from "./screens/Books";
import { EditProfileView } from "./screens/EditProfile";
import { ReadBookView } from "./screens/Books/ReadBook";
import { OrderBookView } from "./screens/Books/OrderBook";
import { FAQView } from "./screens/Misc/FAQ";
import { TermsView } from "./screens/Misc/Terms";
import { onDevTools } from "./lib/disable-devtools";
import { SelectSlotView } from "./screens/SelectSlot";
import ExamPageView from "./screens/Exam";
import ExamPage from "./screens/Exam/ExamPage";
import SubmittedPage from "./screens/Exam/ExamSubmitted";
import UploadAnswer from "./screens/Exam/uploadAnswer/upload-answer-page";
import TutorialView from "./screens/Tutorial";
import MockTestView from "./screens/MockTest";
import ExamGuidelinePage from "./screens/MockTest/ExamGuidelinePage";
import MockExamFinish from "./screens/MockTest/MockExamFinish";
import MockUploadAnswer from "./screens/MockTest/MockUploadAnswer";
import AdmitCardView from "./screens/AdmitCard";
import McqExamPage from "./screens/Exam/McqExamPage";
import MockMcqExamPage from "./screens/MockTest/MockMcqExamPage";
import MockExamPage from "./screens/MockTest/MockStartExam";

const authRoutesConfig: RouteProps[] = [
  { path: "/sign-in", element: <SignInView /> },
  { path: "/", element: <SignInView /> },
  { path: "/forgot", element: <ForgotPasswordView /> },
  { path: "/resetpassword", element: <ResetPasswordView /> },
];

const AuthRoutesPaths = authRoutesConfig.map((route) => route.path);

const publicRoutesConfig: RouteProps[] = [
  ...authRoutesConfig,
  { path: "/faq", element: <FAQView /> },
  { path: "/terms", element: <TermsView /> },
  { path: "*", element: <NotFound /> },
  { path: "/upload-answer", element: <UploadAnswer /> },
  { path: "/mockuploadanswer", element: <MockUploadAnswer /> },
];

const PublicRoutesPaths = publicRoutesConfig.map((route) => route.path);

const protectedRoutesConfig: RouteProps[] = [
  { path: "/", element: <DashboardView /> },
  { path: "/applied-posts", element: <AppliedPostsView /> },
  { path: "/admit-card/:submissionId", element: <AdmitCardView /> },
  { path: "/edit-profile", element: <EditProfileView /> },
  { path: "/books", element: <BooksView /> },
  { path: "/books/order-book", element: <OrderBookView /> },
  { path: "/books/read-book", element: <ReadBookView /> },
  { path: "/select-slot", element: <SelectSlotView /> },
  { path: "/tutorial", element: <TutorialView /> },
  { path: "/mocktest", element: <MockTestView /> },
  { path: "/mock/exam", element: <ExamGuidelinePage /> },
  { path: "/mock/exam/start", element: <MockExamPage /> },
  { path: "/mock/mcqexam/start", element: <MockMcqExamPage /> },
  { path: "/mock/exam/finish", element: <MockExamFinish /> },
  { path: "/start", element: <ExamPageView /> },
  { path: "/start/exam", element: <ExamPage /> },
  { path: "/start/mcqexam", element: <McqExamPage /> },
  { path: "/start/exam/finish", element: <SubmittedPage /> },
];

const ProtectedRoutesPaths = protectedRoutesConfig.map((route) => route.path);

const ProtectedRoutes = () => (
  <>
    {protectedRoutesConfig.map(({ path, element }, index) => (
      <Route key={index} path={path} element={element} />
    ))}
  </>
);

const AuthRoutes = () => (
  <>
    {authRoutesConfig.map(({ path, element }, index) => (
      <Route key={index} path={path} element={element} />
    ))}
  </>
);

const PublicRoutes = () => (
  <>
    {publicRoutesConfig.map(({ path, element }, index) => (
      <Route key={index} path={path} element={element} />
    ))}
  </>
);

export const RouteManager = () => {
  const { isAuthenticated } = useAuth();

  process.env.NODE_ENV === "production" && onDevTools();

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          {ProtectedRoutes()}
          {PublicRoutes()}
        </>
      ) : (
        <>
          {AuthRoutes()}
          {PublicRoutes()}
        </>
      )}
    </Routes>
  );
};

export { PublicRoutesPaths, ProtectedRoutesPaths, AuthRoutesPaths };
