import { useEffect, useRef, useState } from "react";
import { Button } from "src/components/ui/button";



import Webcam from "react-webcam";


import { Modal } from "react-overlays";
import Backdrop from "src/components/ui/Backdrop";

import ZoomLinkDialog from "src/components/ui/zoom-link-dialog";

import { toast } from "sonner";
import Terms from "../Exam/Terms";
import { useNavigate } from "react-router-dom";

type View = "guideline" | "capture" | "start";

const ExamGuidelinePage = () => {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [livePictureURL, setLivePictureURL] = useState<string | null>(null);
  const [livePictureBlob, setLivePictureBlob] = useState<Blob | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [view, setView] = useState<View>("guideline");


  const [pictureCaptured, setPictureCaptured] = useState(false);
  const [pictureUploaded, setPictureUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  const webcamRef = useRef<Webcam>(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user",
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });

      if (webcamRef.current?.video) {
        webcamRef.current.video.srcObject = stream;
      }

      setIsCameraOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const capturePicture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();

      if (imageSrc) {
        const blob = dataURItoBlob(imageSrc);

        setLivePictureURL(imageSrc);
        setLivePictureBlob(blob);

        // Mark that a picture has been captured
        setPictureCaptured(true);

        // Remove the webcam feed
        const videoElement = document.querySelector("video");
        if (videoElement) {
          videoElement.style.display = "none";
        }
      }
    }
  };

  const recapturePicture = () => {
    // Reset the pictureCaptured flag
    setPictureCaptured(false);

    // Show the webcam feed again
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.style.display = "block";
    }

    setLivePictureURL(null);
  };

  const stopStream = () => {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      const stream = videoElement.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      videoElement.srcObject = null;
    }
    setUploading(false);
    setPictureUploaded(true);
    toast.success("Picture uploaded Successfully!");
  };

  const onSubmit =() => {
      navigate(
        `/mock/mcqexam/start`,
      );
  };

  const uploadLivePicture = () => {
    setUploading(true);
    stopStream();
  };

  // Utility function to convert data URI to Blobz
  const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  // countdown timer

  const handleZoomLink = () => {
    window.open("https://us05web.zoom.us/j/89819939423?pwd=Di17GS0TRg5awawEySEloxaMZXeAMl.1", "_blank", "noopener,noreferrer");
    setIsModalOpen(false);
  };

  const [activeStartBtn, setActiveStartBtn] = useState(false);

  const handleExamStartTimerEnd = () => {
    setActiveStartBtn(true);
  };

  const renderView = () => {
    switch (view) {
      case "guideline":
        return (
          <>
          <div className="flex h-[90vh] w-full flex-col justify-center text-center pb-8">
            <h1 className="mb-4 rounded-md bg-gray-100 p-2 text-2xl font-semibold">
              Exam Guidelines
            </h1>
            <div className="overflow-y-auto text-start text-sm text-gray-600 md:text-base">
              <Terms />
              <p className="text-red-500 font-bold text-lg text-center">Your video will be recorded. If any suspicious activities are observed during the exam, it may result in disqualification from the final interview round due to a lack of honesty.</p>
              <div className="mt-4 flex justify-center">
                <div>
                  <input
                    type="checkbox"
                    id="terms"
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                  />
                  <label
                    className="ml-2 text-sm font-medium leading-none"
                    htmlFor="terms"
                  >
                    Accept terms and conditions of the exam rules
                  </label>
                </div>
              </div>
            </div>

            <button
              className={`mt-4 w-full rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 ${
                !acceptedTerms && "btn-disabled opacity-50"
              }`}
              onClick={() => {
                setView("capture");
              }}
              disabled={!acceptedTerms}
            >
              Next
            </button>
          </div>
      </>
        );
      case "capture":
        return (
          <>
          <div className="flex h-[90vh] w-full flex-col justify-center text-center pb-8">
            <div className="mx-auto flex w-1/2 basis-1/2 flex-col items-center justify-center gap-5">
              <span>Click a live picture</span>
              <div
                id="live-picture-container"
                className={`flex flex-col justify-center ${
                  !livePictureURL && "h-[19rem] w-[30rem] outline outline-1"
                }`}
              >
                {isCameraOpen && <Webcam ref={webcamRef} />}
              </div>
              {!isCameraOpen ? (
                <Button onClick={startCamera}>Start Camera</Button>
              ) : (
                <>
                  {!pictureCaptured ? (
                    <Button className="my-3" onClick={capturePicture}>
                      Capture Picture
                    </Button>
                  ) : (
                    <>
                      <picture>
                        <img src={livePictureURL!} alt="Live Capture" />
                      </picture>
                      <Button
                        className="my-2"
                        onClick={recapturePicture}
                        disabled={pictureUploaded}
                      >
                        Recapture?
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
            <button
              onClick={uploadLivePicture}
              disabled={pictureUploaded || !livePictureURL}
              className="mx-auto my-3 mt-8 w-56 rounded-md border border-green-600 bg-[#ace1af] px-3 py-2 text-[1rem] font-semibold text-black hover:bg-[#ace1af]/80 disabled:bg-[#ace1af]/30 disabled:text-gray-400"
            >
              {uploading ? "Uploading..." : "Upload Live Picture"}
            </button>
            <button
              className={`mx-auto mt-40 w-[50%] rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 ${
                !pictureUploaded && "btn-disabled opacity-50"
              }`}
              onClick={() => {
                setView("start");
              }}
              disabled={!pictureUploaded}
            >
              Next
            </button>
            </div>
          </>
        );
      case "start":
        return (
          <>
            <div className="flex h-[90vh] w-full flex-col justify-center text-center pb-8">
              <div className="flex h-full w-full flex-col items-center justify-center gap-5 rounded bg-slate-100 p-4 shadow-md">
                <div className="mb-4 text-center text-2xl font-bold text-red-600">
                  Please make sure before starting the Exam
                </div>
                <div className="mx-auto mb-4 w-fit text-xl">
                  <p>
                    &#10153; Do not Refresh or Change tabs during your Exam.
                  </p>
                  <p className="my-2 text-start text-red-500">
                    &#10153; <span className="font-bold">Join Zoom</span> before
                    starting the Exam
                  </p>
                  <p className="mb-4 mt-2">
                    &#10153; Follow the Proctor's instruction, don't be in
                    hurry.
                  </p>
                  <ZoomLinkDialog onClick={handleZoomLink}>
                    <Button className="my-3 px-16 py-2 text-lg font-bold">
                      Join Zoom
                    </Button>
                  </ZoomLinkDialog>
                </div>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <section className="mx-auto h-screen max-w-screen-2xl place-items-center">
        <div>
          <h1 className="text-3xl font-semibold text-center">NHWM</h1>
          <h1 className="text-3xl font-extrabold text-center">Basic Recruitment Test</h1>
        </div>
        <div className="flex h-[80vh] items-center">
          <div className="mx-auto flex h-[50vh] min-h-max w-2/5 flex-col items-center justify-center">
            <div className="mb-6">
              <p className="text-xl text-center text-red-500 mb-6 font-semibold">The time allocated for each section in this Mock Test differs from the actual exam. The purpose of this Mock Test is to help you become familiar with the exam environment and procedures.</p>
              <p className="text-center font-mono text-2xl">
                You can Start the Mock Exam in{" "}
                <CountdownTimer
                  onTimerEnd={handleExamStartTimerEnd}
                />
              </p>
            </div>
            <div className="flex w-full flex-col items-center gap-3">
              
              <input
                className="h-8 w-[30%] rounded-md border border-secondary bg-slate-50 p-2 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                type="text"
                defaultValue="OH-12345Z1234"
                alt="admit card number"
                placeholder="Admit Card Number"
              />
              {
                <Button
                  type="submit"
                  className="mx-auto w-3/4 text-lg font-bold"
                  disabled = {!activeStartBtn}
                  onClick={onSubmit}
                >
                  Click here to Start the Exam
                </Button>
              }
              <h1 className="text-lg font-semibold text-red-500">
                Make sure that your Zoom Camera is{" "}
                <span className="text-lg font-bold">ON</span> before starting
                the Exam.
              </h1>
              </div>
          </div>
        </div>
      </section>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        onEscapeKeyDown={(e: KeyboardEvent) => e.preventDefault()}
        aria-labelledby="exam rules!"
        backdrop={true}
        renderBackdrop={(props) => <Backdrop {...props} />}
        style={{
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.9)",
          padding: "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          background: "white",
          zIndex: 1040,
        }}
      >
        <div>
          <div className="xl:hidden">
              <h1 className="mb-4 rounded-md bg-gray-100 p-2 text-center text-2xl font-semibold">
                You can’t Attempt the Exam from Mobile device, Tablet or Small Layout Screen of Browser. Use Desktop or Laptop in the Full Screen Layout  of the Browser.
              </h1>
          </div>
          <div className="hidden xl:block">
            {renderView()}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExamGuidelinePage;

interface CountdownTimerProps {
  onTimerEnd: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  onTimerEnd,
}) => {
  const [remainingTime, setRemainingTime] = useState<string>("00:00");

  const serverTimeString = new Date().toUTCString();
  const serverTime = new Date(serverTimeString).getTime();
  const localTime = Date.now();
  const offset = serverTime - localTime;
  const targetTimemili = new Date().getTime() + 1 * 60 * 1000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const adjustedTime = now + offset;

      const timeRemaining = Math.max(0, targetTimemili - adjustedTime);

      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        onTimerEnd();
      } else {
        const minutes = Math.floor(timeRemaining / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
        setRemainingTime(
          `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
        );
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  return (
    <>
      <span className="text-3xl">{remainingTime}</span>
    </>
  );
};
