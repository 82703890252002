import { Card, CardContent } from "src/components/ui/card";
import { useNavigate } from "react-router-dom";

interface NavigationCardProps {
  imageSrc: string;
  label: string;
  href: string;
}

export const NavigationCard = ({
  imageSrc,
  label,
  href,
}: NavigationCardProps) => {
  const navigate = useNavigate();
  return (
    <Card className="relative w-[200px] border-0 shadow-lg hover:shadow-2xl md:h-[320px] md:w-[222px] cursor-pointer" onClick={() => navigate(href)}>
      <CardContent className="flex h-full w-full flex-col items-center justify-center gap-6 p-2">
        <img
          src={imageSrc}
          height={150}
          width={150}
          alt=""
          className="size-44 rounded-lg object-contain md:size-56"
        />
        <div className="text-center font-semibold md:text-xl">{label}</div>
        <svg
          width="24"
          height="20"
          viewBox="0 0 24 20"
          className="absolute bottom-3 right-3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.175 10.8333H4V9.16668H16.175L10.575 4.50001L12 3.33334L20 10L12 16.6667L10.575 15.5L16.175 10.8333Z"
            fill="#5F6368"
          />
        </svg>
      </CardContent>
    </Card>
  );
};
