import { Card, CardContent } from "src/components/ui/card";
import { Skeleton } from "src/components/ui/skeleton";
import { cn } from "src/lib/utils";

interface MobileAppliedPostProps {
  imageSrc: string;
  postApplied: string;
  submissionId: number;
  baseDistrictOrState: string;
  speciality?: string;
  viewAdmitCard: (id: number) => void;
  isScheduled: number[];
}

export const AppliedPost = ({
  imageSrc,
  submissionId,
  postApplied,
  speciality,
  baseDistrictOrState,
  viewAdmitCard,
  isScheduled,
  ...props
}: MobileAppliedPostProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Card
      {...props}
      className={cn(
        `w-44 border-0 bg-zinc-200 shadow-none  ${isScheduled.includes(submissionId) ? "cursor-pointer shadow-lg shadow-[#94d697] hover:shadow-lg hover:shadow-primary" : ""}`,
      )}
      onClick={() => {
        if (isScheduled.includes(submissionId)) {
          viewAdmitCard(submissionId);
        }
      }}
    >
      <CardContent className="p-2">
        <img
          src="/application.png"
          height={150}
          width={150}
          alt={imageSrc}
          className="size-44 rounded-lg object-contain"
        />
        <div className={cn("flex flex-col px-2 ")}>
          <span className="text-lg font-medium uppercase">{postApplied}</span>
          <span className="text-md uppercase">
            {baseDistrictOrState === "Not applicable"
              ? ""
              : baseDistrictOrState}
          </span>
        </div>
        <div className="text-center text-lg font-medium text-green-600">
          {isScheduled.includes(submissionId) ? "View Admit" : ""}
        </div>
      </CardContent>
    </Card>
  );
};

export const AppliedPostSkeleton = () => (
  <Skeleton className="h-60 w-44 border-0 shadow-none">
    <div className="space-y-1 p-2">
      <Skeleton className="size-40 rounded-lg" />
      <Skeleton className="h-7 w-24" />
      <Skeleton className="h-6 w-24" />
    </div>
  </Skeleton>
);
