

interface VideoComponentProps {
  src : string;
}

const VideoComponent = ({src}: VideoComponentProps) => {

  return (
    <div className='aspect-video max-w-[1244px] max-h-[700px] w-full h-0 pb-[56.25%] relative mx-auto"'>
      <iframe
        src={src}
        allow="autoplay"
        title="English Video Tutorial"
        allowFullScreen
        className="absolute top-0 left-0 w-full h-full"
      ></iframe>
    </div>
  );
};

export default VideoComponent;
