import { DialogClose } from "@radix-ui/react-dialog";
import { ReactNode } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";

interface ZoomLinkDialogProps {
  children: ReactNode;
  onClick: () => void;
}

const ZoomLinkDialog = ({ children, onClick }: ZoomLinkDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle className="text-center">
            Join the Zoom Meeting to Start the Exam.
          </DialogTitle>
        </DialogHeader>
        <div className="flex justify-center space-y-1">
          <DialogClose>
            <button
              className="inline-flex h-9 items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 py-2 text-sm font-medium text-white transition-colors hover:border hover:border-primary hover:bg-[#8B70D7]/60 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
              onClick={onClick}
            >
              Go to Zoom
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ZoomLinkDialog;
