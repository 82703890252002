import { DashboardLayout } from "../Dashboard/DashboardLayout"
import { MockExams } from "./MockExamTable"


const MockTestView = () => {
  return (
    <DashboardLayout>
      <div className="flex flex-col justify-between w-full">
        <h1 className="text-3xl font-bold mx-4 my-8">Mock test</h1>
<div>
  <h1 className="text-3xl text-center text-red-500 font-bold w-[80%] mx-auto my-6">Mock Test is for Practice Purpose only</h1>
  <h1 className="text-3xl text-center text-red-500 font-bold w-[80%] mx-auto my-6">Mock Test is also only Desktop or Laptop Compatible</h1>
          <h1 className="text-2xl text-center text-red-500 font-bold w-[80%] mx-auto">The time allocated and the Questions in this Mock Test differs from the actual exam. The purpose of this Mock Test is to help you become familiar with the exam environment and procedures</h1>
</div>
          <MockExams/>
      </div>
    </DashboardLayout>
  )
}

export default MockTestView