"use client";

import { useNavigate } from "react-router-dom";
import { Button } from "src/components/ui/button";


export default function MockExamFinish() {

  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen justify-center items-center max-w-screen-lg mx-auto space-y-10 p-10">
      <span className="text-xl text-center md:text-3xl">
        Thank you for submitting exam online.
        <br />
        We anticipate releasing the exam results soon.
        <br />
        Please stay tuned for further updates
      </span>
      <Button variant="default"
        onClick={() => {
          navigate('/');
        }}
      >
        Home
      </Button>
    </div>
  );
}
