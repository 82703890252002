import { NavigationCards } from "./navigation-cards";
import { DashboardLayout } from "./DashboardLayout";

import { Exams } from "src/components/ui/exams";
import { DashboardHeader } from "./dashboard-header";
import { Notifications } from "./notifications";
import { FAQCard } from "src/components/ui/faq-card";
import GuidelineBubble from "src/components/ui/guideline-bubble";
import { Link } from "react-router-dom";
import { ArrowRightToLine } from "lucide-react";

export const DashboardView = () => {
  return (
    <DashboardLayout>
      <main className="flex flex-grow flex-col gap-8 px-2 py-3">
        <DashboardHeader />
        <section className="flex h-full w-full flex-col items-center justify-center gap-8 md:gap-20">
          <div className="flex flex-col gap-20 justify-self-center md:flex-row">
            <NavigationCards />
          </div>
          <div className="flex flex-wrap gap-1 sm:gap-20 justify-center items-center">
            <Link to="/tutorial">
            <div className="rounded-md hover:shadow-slate-400 hover:shadow-lg hover:bg-violet-400 bg-primary text-white w-fit h-fit px-3 py-2 font-semibold flex justify-center items-center">
              <div className="flex flex-col justify-center items-center">
                <h1 className="text-lg uppercase">Video Tutorials</h1>
                <h1>(Slot Booking & Exam Guide)</h1>
              </div>
              <ArrowRightToLine />
              </div>
            </Link>
            <Link to="/mocktest">
            <div className="rounded-md hover:shadow-slate-400 hover:shadow-lg hover:bg-violet-400 bg-primary text-white w-[268px] h-[68px] text-lg font-semibold flex justify-center items-center gap-3">
              <h1 className="uppercase tex-xl">Mock Test</h1>
              <ArrowRightToLine />
            </div>
            </Link>
          </div>
        </section>
        <Notifications />
        <div>
          <div className="mb-10 flex justify-center">
            <h1 className="text-xl font-bold my-3 w-[70%] text-center text-red-500">To avoid missing the exam, You must click the "Start Exam" button 10 minutes earlier of the actual exam time</h1>
          </div>
          <div className="mx-auto overflow-auto rounded-lg border p-3 shadow-lg min-h-[250px] lg:min-h-[210px] lg:w-[600px] xl:w-[800px]">
            <Exams />
          </div>
        </div>
        <FAQCard className="pt-16 lg:hidden" />
      </main>
      <FAQCard className="absolute bottom-3 left-10 hidden w-[330px] lg:block" />
      <GuidelineBubble />
    </DashboardLayout>
  );
};
