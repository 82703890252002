import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "src/components/ui/button"
import { getAdmitCard } from "src/redux/api/admit-card";
import AdmitCardLayout from "./AdmitCardLayout";
import { AdmitCardDetail } from "src/lib/entities";


const AdmitCardView = () => {

  const navigate = useNavigate();
  const { submissionId } = useParams();

  const [admitCard, setAdmitCard] = useState<AdmitCardDetail>();
  const [loading, setLoading] = useState(true);

  const admitCardDetail = async (id: number) => {
    setLoading(true)
    try {
      const response = await getAdmitCard(id);
      if (response) {
        setAdmitCard(response);
      }
    } catch (error) {
      console.error(error, "something went wrong.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (submissionId) {
      admitCardDetail(Number(submissionId));
    }
  }, [submissionId]);

  return (
    <div className="m-auto max-w-[1200px]">
            <div className="flex justify-between p-2 print:hidden">
              <p className="text-2xl">Admit Card</p>
              <div className="join gap-3">
                <Button variant="destructive" onClick={() => navigate(-1)}>
                  Back
                </Button>
              </div>
            </div>
            { 
              !admitCard && loading ? (<div className="flex justify-center py-8"><h1 className="text-xl font-semibold text-primary">Loading your Admit Please wait...</h1></div>) : !loading && admitCard && <AdmitCardLayout schedule={admitCard} />
            }
          </div>
  )
}

export default AdmitCardView