import logo from "../../nhwm.png";
import sig from "../../mdsig.svg";
import { AdmitCardDetail } from "src/lib/entities";
import {
  calculateLastEntryTime,
  calculateReportingTime,
  convertDateToIST,
  convertTimeToIST,
} from "src/lib/utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  UploadAdmitPicSchema,
  UploadSignatureSchema,
} from "src/schemas/upload-admit-pic";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "sonner";
import {
  updateProfilePictureService,
  uploadSignature,
} from "src/redux/api/candidate";
import { env } from "src/lib/env";
import CandidatesInstruction from "./instruction-for-candidate";


interface AdmitCardLayoutProps {
  schedule: AdmitCardDetail;
}

const AdmitCardLayout = ({ schedule }: AdmitCardLayoutProps) => {
  const formpic = useForm<z.infer<typeof UploadAdmitPicSchema>>({
    resolver: zodResolver(UploadAdmitPicSchema),
  });
  const formsig = useForm<z.infer<typeof UploadSignatureSchema>>({
    resolver: zodResolver(UploadSignatureSchema),
  });
  const [pfpPreview, setPfpPreview] = useState("");

  const [isPendingSig, setIsPendingSig] = useState(false);
  const [isPendingPfp, setIsPendingPfp] = useState(false);

  const onUploadPhoto = async (
    values: z.infer<typeof UploadAdmitPicSchema>,
  ) => {
    setIsPendingPfp(true);
    if (values.admitPicture) {
      await updateProfilePictureService(values.admitPicture)
        .then(({ success }) => {
          if (success) {
            toast.success("Photo Updated");
          }
        })
        .catch(() => {
          toast.error("Photo upload unsuccessful");
        })
        .finally(() => {
          setIsPendingPfp(false);
        });
    }
  };

  const [sigPreview, setSigPreview] = useState("");

  const onUploadSignature = async (
    values: z.infer<typeof UploadSignatureSchema>,
  ) => {
    setIsPendingSig(true);
    if (values.signature) {
      await uploadSignature(values.signature).then((success) => {
        if (success) {
          toast.success("Signature Updated");
        }
      });
    }
    setIsPendingSig(false);
  };

  const handlePrintAdmit = () => {
    window.print();
  };

  const uploadPhotoWarning = () => {
    const message = "PLEASE UPLOAD YOUR PHOTO AND SIGNATURE IN THE SIZE AS MENTIONED IN THE EMAIL.";
    window.alert(message);
  }

  useEffect(() => {
    if ((!sigPreview || !pfpPreview) && (!schedule.profilePicture || !schedule.signature)) {
      uploadPhotoWarning();
    }
  },[]);

  return (
    <div>
      <div className="mx-auto max-w-5xl bg-white p-6 shadow-lg print:hidden">
        <header className="mb-4 items-center border-b pb-4 lg:flex">
          <div className="mb-2 flex items-center justify-center">
            <img src={logo} alt="Logo" className="mr-3 w-8 lg:w-24" />
            <div className="flex flex-col justify-center">
              <p className="text-sm font-semibold text-blue-600 lg:text-lg">
                National Healthcare
                <sup className="text-xl text-blue-600">®️</sup>
              </p>
              <p className="text-sm font-semibold text-blue-600 lg:text-lg">
                & Wellness Mission
              </p>
            </div>
          </div>
          <div className="flex-1">
            <div className="m-auto w-fit text-center lg:ml-12">
              <h1 className="text-xl font-bold">
                BASIC RECRUITMENT TEST - 2024
              </h1>
              <h3 className="text-lg font-semibold">NHWM</h3>
              <p className="text-sm">
                A Collaborative Initiative of
                <br />
                Optim Health and Wellness (OPC) Pvt. Ltd.
              </p>
              <h2 className="text-lg font-medium">ADMIT CARD</h2>
            </div>
          </div>
        </header>
        <div className="mb-4 border-2 border-gray-800 p-3 lg:grid lg:grid-cols-2">
          <div className="flex">
            <div>
              <p>
                <strong>Candidate's Name:</strong>
              </p>
              <p className="mt-3">
                <strong>Applied Post:</strong>
              </p>
              <p>
                <strong>State:</strong>
              </p>
            </div>
            <div className="pl-3">
              <p className="font-semibold uppercase text-wrap">
                {schedule.candidateName}
              </p>
              <p className="mt-3 font-semibold">{schedule.postName}</p>
              <p>{schedule.slot.state}</p>
            </div>
          </div>
          <div className="flex lg:justify-end">
            <div>
              <p>
                <strong>Roll Number:</strong>
              </p>
              <p>
                <strong>Admit Card Number:</strong>
              </p>
            </div>
            <div className="pl-3">
              <p className="font-medium">{schedule.rollNo}</p>
              <p className="font-medium">{schedule.admitCardNumber}</p>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="my-2 text-center font-medium">Test Details</h3>
          <div className="flex border border-black overflow-hidden">
            <div className="grow w-full">
              <table className="mb-4 lg:w-full border-collapse border">
                <tbody>
                  <tr>
                    <th className="border px-2 py-1 text-xs lg:text-lg">
                      Date of Test
                    </th>
                    <td className="border px-2 py-1">
                      {convertDateToIST(
                        new Date(schedule.slot.date).toUTCString(),
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1 text-xs lg:text-lg">
                      Slot Selected
                    </th>
                    <td className="border px-2 py-1">
                      {convertTimeToIST(schedule.slot.startTime)}-
                      {convertTimeToIST(schedule.slot.endtime)}
                    </td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1 text-xs lg:text-lg">
                      Timing of Test
                    </th>
                    <td className="border px-2 py-1">1 hour 30 minutes</td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1 text-xs lg:text-lg">
                      Reporting Time
                    </th>
                    <td className="border px-2 py-1">
                      {calculateReportingTime(schedule.slot.startTime)}
                    </td>
                  </tr>
                  <tr>
                    <th className="border px-2 py-1 text-xs lg:text-lg">
                      Last Exam Entry Time
                    </th>
                    <td className="border px-2 py-1">
                      {calculateLastEntryTime(schedule.slot.startTime)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex justify-center items-center">
                <h1 className="text-center text-lg lg:text-xl text-red-500 w-[80%]">Click the 'START EXAM' button on your dashboard at least 10 minutes before the exam start time; otherwise, you will miss the exam and will not be able to enter.</h1>
              </div>
            </div>

            <div className="w-[54%] lg:w-[40%]">
              <div className="h-[280px] border border-black text-center text-xs">
                Passport sized Photograph of the Candidate
                <div className="mb-2 mt-1 flex items-center justify-center">
                  <img
                    src={
                      pfpPreview ||
                      `${env.REACT_APP_CLOUD_FRONT_URL}/${schedule.profilePicture}`
                    }
                    alt=""
                    height={180}
                    width={180}
                    className="aspect-square"
                  />
                </div>
                <div>
                  <Form {...formpic}>
                    <form onSubmit={formpic.handleSubmit(onUploadPhoto)}>
                      <FormField
                        control={formpic.control}
                        name="admitPicture"
                        render={({
                          field: { value, onChange, ...fieldProps },
                        }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                {...fieldProps}
                                type="file"
                                className="block w-full text-sm text-gray-500
                       file:mr-2 file:rounded-full file:border-0
                       file:bg-blue-50 file:px-3
                       file:py-1 file:text-sm file:text-[#1b271c]
                       hover:file:bg-[#c5eac7]"
                                accept="image/*"
                                onChange={(event) => {
                                  const file =
                                    event.target.files && event.target.files[0];
                                  onChange(file);
                                  if (file) {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setPfpPreview(e.target?.result as string);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <button
                        disabled={isPendingPfp}
                        type="submit"
                        className="text-md rounded-md border border-black bg-[#ace1af] px-4 py-1 font-medium"
                      >
                        {!!schedule.profilePicture
                          ? isPendingPfp
                            ? "Update..."
                            : "Update"
                          : isPendingPfp
                            ? "Save..."
                            : "Save"}
                      </button>
                    </form>
                  </Form>
                </div>
              </div>
              <div className="h-[180px] border border-black text-center text-xs">
                Signature of the Candidate
                <div className="my-3 flex items-center justify-center">
                  <img
                    src={
                      sigPreview ||
                      `${env.REACT_APP_CLOUD_FRONT_URL}/${schedule?.signature}`
                    }
                    alt=""
                    height={80}
                    width={220}
                    className="aspect-[3/1]"
                  />
                </div>
                <div>
                  <Form {...formsig}>
                    <form onSubmit={formsig.handleSubmit(onUploadSignature)}>
                      <FormField
                        control={formsig.control}
                        name="signature"
                        render={({
                          field: { value, onChange, ...fieldProps },
                        }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                {...fieldProps}
                                type="file"
                                className="block w-full text-sm text-gray-500
                       file:mr-2 file:rounded-full file:border-0
                       file:bg-blue-50 file:px-3
                       file:py-1 file:text-sm file:text-[#1b271c]
                       hover:file:bg-[#c5eac7]"
                                accept="image/*"
                                onChange={(event) => {
                                  const file =
                                    event.target.files && event.target.files[0];
                                  onChange(file);
                                  if (file) {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setSigPreview(e.target?.result as string);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <button
                        disabled={isPendingSig}
                        type="submit"
                        className="text-md rounded-md border border-black bg-[#ace1af] px-4 py-1 font-medium"
                      >
                        {!!schedule.signature
                          ? isPendingSig
                            ? "Update..."
                            : "Update"
                          : isPendingSig
                            ? "Save..."
                            : "Save"}
                      </button>
                    </form>
                  </Form>
                </div>
              </div>
              <div className="flex h-[100px] flex-col items-center border border-black text-xs">
                <p>Signature of the Mission Director</p>
                <img
                  src={sig}
                  alt="signature or the mission director"
                  className="w-28"
                />
              </div>
            </div>
          </div>
        </div>
        <CandidatesInstruction />
      </div>

      {/* for printing admit */}
      <div id="admitCard">
        <div className="mx-auto hidden max-w-5xl bg-white p-6 shadow-lg print:block">
          <header className="mb-4 items-center border-b pb-4 lg:flex">
            <div className="mb-2 flex items-center justify-center">
              <img src={logo} alt="Logo" className="mr-3 w-8 lg:w-24" />
              <div className="flex flex-col justify-center">
                <p className="text-sm font-semibold text-blue-600 lg:text-lg">
                  National Healthcare
                  <sup className="text-xl text-blue-600">®️</sup>
                </p>
                <p className="text-sm font-semibold text-blue-600 lg:text-lg">
                  & Wellness Mission
                </p>
              </div>
            </div>
            <div className="flex-1">
              <div className="m-auto w-fit text-center lg:ml-12">
                <h1 className="text-xl font-bold">
                  BASIC RECRUITMENT TEST - 2024
                </h1>
                <h3 className="text-lg font-semibold">NHWM</h3>
                <p className="text-sm">
                  A Collaborative Initiative of
                  <br />
                  Optim Health and Wellness (OPC) Pvt. Ltd.
                </p>
                <h2 className="text-lg font-medium">ADMIT CARD</h2>
              </div>
            </div>
          </header>
          <div className="mb-4 border-2 border-gray-800 p-3 sm:flex sm:justify-between lg:grid lg:grid-cols-2">
            <div className="flex">
              <div>
                <p>
                  <strong>Candidate's Name:</strong>
                </p>
                <p className="mt-3">
                  <strong>Applied Post:</strong>
                </p>
                <p>
                  <strong>State:</strong>
                </p>
              </div>
              <div className="pl-3">
                <p className="font-semibold uppercase">
                  {schedule.candidateName}
                </p>
                <p className="mt-3 font-semibold">{schedule.postName}</p>
                <p>{schedule.slot.state}</p>
              </div>
            </div>
            <div className="flex lg:justify-end">
              <div>
                <p>
                  <strong>Roll Number:</strong>
                </p>
                <p>
                  <strong>Admit Card Number:</strong>
                </p>
              </div>
              <div className="pl-3">
                <p className="font-medium">{schedule.rollNo}</p>
                <p className="font-medium">{schedule.admitCardNumber}</p>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h3 className="my-2 text-center font-medium">Test Details</h3>
            <div className="flex border border-black">
              <div className="w-full grow">
                <table className="mb-4 w-full border-collapse border">
                  <tbody>
                    <tr>
                      <th className="border px-2 py-1">Date of Test</th>
                      <td className="border px-2 py-1">
                        {convertDateToIST(
                          new Date(schedule.slot.date).toUTCString(),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th className="border px-2 py-1">Slot Selected</th>
                      <td className="border px-2 py-1">
                        {convertTimeToIST(schedule.slot.startTime)}-{" "}
                        {convertTimeToIST(schedule.slot.endtime)}
                      </td>
                    </tr>
                    <tr>
                      <th className="border px-2 py-1">Timing of Test</th>
                      <td className="border px-2 py-1">1 hour 30 minutes</td>
                    </tr>
                    <tr>
                      <th className="border px-2 py-1">Reporting Time</th>
                      <td className="border px-2 py-1">
                        {calculateReportingTime(schedule.slot.startTime)}
                      </td>
                    </tr>
                    <tr>
                    <th className="border px-2 py-1 text-xs lg:text-lg">
                      Last Exam Entry Time
                    </th>
                    <td className="border px-2 py-1">
                      {calculateLastEntryTime(schedule.slot.startTime)}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div className="flex justify-center items-center">
                <h1 className="text-center text-lg lg:text-xl text-red-500 w-[80%]">Click the 'START EXAM' button on your dashboard at least 10 minutes before the exam start time; otherwise, you will miss the exam and will not be able to enter.</h1>
              </div>
              </div>

              <div className="w-[40%] lg:w-[25%]">
                <div className="h-[220px] border border-black text-center text-xs">
                  Photograph of the Candidate
                  <div className="mb-2 mt-1 flex items-center justify-center">
                    <img
                      src={
                        pfpPreview ||
                        `${env.REACT_APP_CLOUD_FRONT_URL}/${schedule.profilePicture}`
                      }
                      alt="Candidate's Photograph"
                      height={180}
                      width={180}
                      className="aspect-square"
                    />
                  </div>
                </div>
                <div className="h-[120px] border border-black text-center text-xs">
                  Signature of the Candidate
                  <div className="my-3 flex items-center justify-center">
                    <img
                      src={
                        sigPreview ||
                        `${env.REACT_APP_CLOUD_FRONT_URL}/${schedule?.signature}`
                      }
                      alt="candidate upload"
                      height={80}
                      width={220}
                      className="aspect-[3/1]"
                    />
                  </div>
                </div>
                <div className="flex h-[100px] flex-col items-center border border-black text-xs">
                  <p>Signature of the Mission Director</p>
                  <img
                    src={sig}
                    alt="signature or the mission director"
                    className="w-28"
                  />
                </div>
              </div>
            </div>
          </div>
          <CandidatesInstruction />
        </div>
      </div>

      <div className="my-8 flex flex-col items-center justify-center print:hidden">
        <p
          className={`${(!!sigPreview && !!pfpPreview) || (!!schedule.profilePicture && !!schedule.signature) ? "hidden" : ""} text-md my-2 text-center text-red-500`}
        >
          Upload Passport size Photograph and Signature to Print the Admit
        </p>
        <button
          className="rounded-md border border-[#2d3e2e] bg-[#ace1af] px-2 py-1 disabled:border-gray-400 disabled:bg-[#d6f0d7] disabled:text-gray-400"
          onClick={handlePrintAdmit}
          disabled={
            (!sigPreview || !pfpPreview) &&
            (!schedule.profilePicture || !schedule.signature)
          }
        >
          Print Admit
        </button>
      </div>
    </div>
  );
};

export default AdmitCardLayout;
