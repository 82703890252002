import * as Popover from "@radix-ui/react-popover";
import { CircleAlert } from "lucide-react";

const GuidelineBubble = () => {
  return (
    <section className="absolute bottom-8 right-8 hidden p-3 lg:block">
      <div className="animate-bounce rounded-full border border-primary p-2">
        <Popover.Root>
          <Popover.Trigger asChild>
            <CircleAlert size={40} className="cursor-pointer" />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade w-[320px] rounded bg-white p-5 mr-6 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)]"
              sideOffset={7}
            >
              <div className="flex flex-col gap-2.5">
                <p>Make sure to download Zoom before appearing the exam.</p>
                <a
                  href="https://zoom.us/download?os=win"
                  rel="noreferrer"
                  target="_blank"
                  className="text-sm text-indigo-600"
                >
                  Click here to download Zoom.
                </a>
                <p>Make sure your internet speed is minimum 10mbps.</p>
                <a
                  href="https://www.speedtest.net/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-sm text-indigo-600"
                >
                  Click here to check you internet speed.
                </a>
                <p>Make sure your mobile has a scanner.</p>
              </div>
              <Popover.Arrow className="fill-white" />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    </section>
  );
};

export default GuidelineBubble;
