import VideoComponent from "src/components/ui/VideoComponent"
import { DashboardLayout } from "../Dashboard/DashboardLayout"


const TutorialView = () => {

  const urlEnglish = "https://drive.google.com/file/d/1zsNzMCpl4gWacGL8WRcp6Gm2wymeki7U/view?usp=sharing";
  
  const urlHindi = "https://drive.google.com/file/d/1wB29T8X2W5R9hzkRr2y-nohT52ICmSwm/view?usp=sharing";

  const slotUrlEnglish = "https://drive.google.com/file/d/1dBVJvQnh1M5vfvdPJBdT9oweKRKmb-Pk/view?usp=sharing";
  const slotUrlHindi = "https://drive.google.com/file/d/1ZFLFK-YG8u_WvMWVeL4CAEgbUv-L8ya-/view?usp=sharing";

  function getEmbedLink(driveLink: string) {

  const fileIdMatch = driveLink.match(/(?:\/d\/|id=|\/uc\?id=)([a-zA-Z0-9_-]+)/);
  
  if (fileIdMatch && fileIdMatch[1]) {
    const fileId = fileIdMatch[1];

    return `https://drive.google.com/file/d/${fileId}/preview`;
  } else {
      console.error("Invalid Google Drive link.");
      return null;
    }
  }

  const ENGLISH = getEmbedLink(urlEnglish);
  const HINDI = getEmbedLink(urlHindi);
  const SLOTENGLISH = getEmbedLink(slotUrlEnglish);
  const SLOTHINDI = getEmbedLink(slotUrlHindi);

  return (
    <DashboardLayout>
      <div className="w-full h-fit xl:overflow-auto xl:h-full flex flex-col justify-center xl:block">
        <div className="mx-auto my-6 flex flex-col justify-center items-center border-b border-slate-400 pb-5">
          <h1 className="text-primary text-lg xl:text-xl my-6 text-center font-semibold">Comprehensive Video Guide: How to Book the Exam Slot (English)</h1>
          <VideoComponent src = {SLOTENGLISH!}/> 
        </div>
        
         <div className=" mx-auto my-6 flex flex-col justify-center items-center border-b border-slate-400 pb-5">
          <h1 className="text-primary text-lg xl:text-xl my-6 text-center font-semibold">Comprehensive Video Guide: How to Book the Exam Slot (Hindi)</h1>
          <VideoComponent src = {SLOTHINDI!}/> 
         </div>
        <div className="mx-auto my-6 flex flex-col justify-center items-center border-b border-slate-400 pb-5">
          <h1 className="text-primary text-lg xl:text-xl my-6 text-center font-semibold">Comprehensive Video Guide: How to Successfully Take the Exam (English)</h1>
          <VideoComponent src = {ENGLISH!}/> 
        </div>
        
         <div className=" mx-auto my-6 flex flex-col justify-center items-center border-b border-slate-400 pb-5">
          <h1 className="text-primary text-lg xl:text-xl my-6 text-center font-semibold">Comprehensive Video Guide: How to Successfully Take the Exam (Hindi)</h1>
          <VideoComponent src = {HINDI!}/> 
         </div>
        
      </div>
    </DashboardLayout>
  )
}

export default TutorialView