"use client";
import { ProfilePicture } from "../ui/profile-picture";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "src/components/ui/sheet";
import { BookA, BookIcon, FileText, HomeIcon, LogOut, SettingsIcon, Video } from "lucide-react";
import { MobileNavButton } from "./mobile-nav-button";
import { LogOutDialog } from "src/components/ui/log-out-dialog";
import { ScrollArea, ScrollBar } from "src/components/ui/scroll-area";
import { useFetchCandidate } from "src/redux/api/hooks/useFetchCandidate";
import { MobileSidebarButton } from "./mobile-sidebar-button";

export const MobileSidebar = ({ children }: { children: React.ReactNode }) => {
  const { candidate } = useFetchCandidate();

  const navItems = [
    { label: "Dashboard", icon: HomeIcon, route: "/" },
    { label: "Applied Posts", icon: BookA, route: "/applied-posts" },
    { label: "Books", icon: BookIcon, route: "/books" },
    { label: "Tutorial", icon: Video, route: "/tutorial" },
    { label: "Mock Test", icon: FileText, route: "/mocktest" },
    { label: "Settings", icon: SettingsIcon, route: "/edit-profile" },
  ];

  return (
    <Sheet>
      <SheetTrigger>{children}</SheetTrigger>
      <SheetContent side="left">
        <SheetHeader>
          <SheetTitle>Candidate Profile</SheetTitle>
        </SheetHeader>
        <hr />
        <ScrollArea className="h-[90dvh]">
          <div className="h-full space-y-4 py-3">
            <section className="flex flex-col items-center py-3">
              <div className="flex flex-col items-center justify-center gap-y-3">
                <ProfilePicture />
                <div className="text-lg font-medium">
                  {candidate?.candidateName || "Loading..."}
                </div>
              </div>
            </section>
            <section className="space-y-2">
              {navItems.map((item) => (
                <MobileNavButton
                  key={item.route}
                  route={item.route}
                  Icon={item.icon}
                  label={item.label}
                />
              ))}
            </section>
            <LogOutDialog>
              <MobileSidebarButton>
                <span>
                  <LogOut />
                  Logout
                </span>
              </MobileSidebarButton>
            </LogOutDialog>
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
};
